<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      ref="edit_field"
      :is-edit="true"
      :fields="fields"
      :row-data="row_data"
      post-api="/group/UpdateGroup"
      finish-router="group"
      @beforeSubmit="beforeSubmit"
      permission="Gro_Edit"
    >
      <template #facility="scope">
        <slot-facility :company-id="scope.rowData.companyID" :facility-id="scope.rowData.facilityID"
                       :row-data="scope.rowData" :is-edit="scope.isEdit"
        />
      </template>

      <template #staff="scope">
        <slot-staff :row-data="scope.rowData" :facility-id="scope.rowData.facilityID"/>
      </template>

      <template #bed="scope">
        <slot-bed
          ref="slot_bed"
          :row-data="scope.rowData"
          :facility-id="scope.rowData.facilityID"
          @addBed="addBed"
          @changeBedFacilityId="changeBedFacilityId"
          :slotConfig="scope.slotConfig"
        />
      </template>

      <template #member="scope">
        <slot-member ref="slot_member" :row-data="scope.rowData" :facility-id="scope.rowData.facilityID"
                     @addMember="addMember" @changeMemberFacilityId="changeMemberFacilityId"
        />
      </template>

      <template #alertTime="scope">
        <slot-alert-time
          ref="slot_alert_time"
          :row-data="scope.rowData"
          :root-item="scope.rootItem"
        />
      </template>

    </edit-field>

    <modal-bed ref="model_bed" :facility-id="row_data.facilityID" @changeBed="changeBed"/>

    <modal-member ref="model_member" :facility-id="row_data.facilityID" @changeMember="changeMember"/>

  </page-content>
</template>

<script>
import fields, { reloadFieldData } from '@/views/group/fields'
import EditField from '@/components/EditField2'
import PageContent from '@/components/PageContent'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import SlotFacility from '@/views/group/SlotFacility'
import SlotStaff from '@/views/group/SlotStaff'
import SlotBed from '@/views/group/SlotBed'
import SlotMember from '@/views/group/SlotMember'
import SlotAlertTime from '@/views/group/SlotAlertTime'
import ModalBed from '@/views/group/ModalBed'
import ModalMember from '@/views/group/ModalMember'
import fun from '@/views/group/fun.js'

export default {
  name: 'Edit',
  components: {
    ModalMember,
    ModalBed,
    SlotAlertTime,
    SlotMember,
    SlotBed,
    SlotStaff,
    SlotFacility,
    EditField,
    EditFieldHeader,
    PageContent,
    fields,
  },
  data() {
    return {
      title: common.getMenuName('group') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields,
      row_data: {}
    }
  },
  created() {
    reloadFieldData()
  },
  mounted() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.push({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/group/GetGroup?groupID=' + this.id)
      .then(res => {
        this.row_data = res.data.group
      })
  },
  methods: {
    addBed: function () {
      fun.addBed(this)
      this.$refs.model_bed.checkSelectAll()
    },
    changeBed: function (type, option) {
      fun.changeBed(this, type, option)
    },
    addMember: function () {
      fun.addMember(this)
      this.$refs.model_member.checkSelectAll()
    },
    changeMember: function (type, option) {
      fun.changeMember(this, type, option)
    },
    beforeSubmit: function (callback) {
      const res_1 = this.$refs.slot_bed ? this.$refs.slot_bed.buildData() : true
      const res_2 = this.$refs.slot_member ? this.$refs.slot_member.buildData() : true
      const res_3 = this.$refs.slot_alert_time ? this.$refs.slot_alert_time.buildData() : true

      let rawData = JSON.parse(JSON.stringify(this.$refs.edit_field.rowData))
      if(rawData.isDefaultAlert === true) {
        rawData.alertTimes = []
      }
      callback(res_1 && res_2 && res_3, rawData)
    },
    changeBedFacilityId: function (facilityID) {
      this.$refs.model_bed.changeFacilityId(facilityID)
    },
    changeMemberFacilityId: function (facilityID) {
      this.$refs.model_member.changeFacilityId(facilityID)
    }

  }
}
</script>

<style scoped>

</style>
