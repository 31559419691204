<template>
  <div>
    <modal
      :title="$t('group.addMember')"
      :confirm-fun="runAddMember"
      :cancel-fun="hideModal"
      :visible="show_modal"
      :ok-only="true"
      :no-close-on-backdrop="true"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <b-form-input type="text" v-model="search_content" :placeholder="$t('group.search_member_tips')"/>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="pb-1 pl-1 pr-1 mt-1 group-edit" style="height: 200px; overflow-y: scroll">
          <b-row v-if="member_options.length !== 0">
            <b-col>
              <b-form-checkbox
                :value="true"
                v-model="select_all"
                class="mt-1 ml-1 mr-1"
                @change="changeAllMember"
              >
                {{$t('common.select_all')}}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6" v-for="option in member_options">
              <b-form-checkbox
                :value="option.memberID"
                v-model="member_ids"
                class="mt-1 ml-1 mr-1"
                @change="changeMember(option)"
              >
                {{option.memberName}}（{{option.bedNumber}}）
              </b-form-checkbox>


            </b-col>
          </b-row>
        </div>

      </b-form>
    </modal>

  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BForm, BFormInput, BFormGroup, BCardText,BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import common from "@/common";
import Modal from "@/components/Modal";

export default {
  name: "ModalMember",
  components: {
    Modal,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCardText,
    vSelect,
    BFormCheckbox
  },
  props: {
    facilityId: {type: Number}
  },
  data() {
    return {
      show_modal: false,
      facility_id: this.facilityId ?? common.getFacilityId(),
      search_content: '',
      member_ids: [],
      member_options: [],
      select_all: false
    }
  },
  watch: {
    facilityId: function (facilityId) {
      this.facility_id = facilityId
      this.getMember()
    },
    search_content: function () {
      this.member_options = []
      this.getMember()
    },
  },
  mounted() {
    this.getMember()
  },
  methods: {
    runAddMember: function () {
      this.show_modal = false
    },
    hideModal: function () {
      this.show_modal = false
    },
    changeMember: function (option) {
      const type = this.member_ids.includes(option.memberID) ? 'add' : 'delete'
      this.checkSelectAll()
      this.$emit('changeMember', type, option)
    },
    getMember: function () {
      const facility_id = this.facility_id ? this.facility_id : 0
      common.apiGetData('/group/GetMemberBeds?facilityID='+facility_id+'&searchContent='+this.search_content).then(res => {
        this.member_options = res.data.members
        this.checkSelectAll()
      })
    },
    changeFacilityId: function (facilityID) {
      this.facility_id = facilityID
      this.search_content = ''
      this.member_ids = []
      this.member_options = []
      this.getMember()
    },
    changeAllMember: function () {
      for (const i in this.member_options) {
        const is_include = this.member_ids.includes(this.member_options[i].memberID)

        if (this.select_all === true && !is_include) {
          this.member_ids.push(this.member_options[i].memberID)
          this.$emit('changeMember', 'add', this.member_options[i])

        } else if (this.select_all === false && is_include) {
          this.member_ids.splice(this.member_ids.indexOf(this.member_options[i].memberID), 1)
          this.$emit('changeMember', 'delete', this.member_options[i])
        }
      }
    },
    checkSelectAll: function () {
      let flag = true
      for (const i in this.member_options) {
        if (this.member_ids.indexOf(this.member_options[i].memberID) === -1) {
          flag = false
        }
      }

      this.select_all = flag
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>

</style>
