<template>
  <modal
    :title="title"
    :confirm-fun="runAddBed"
    :cancel-fun="hideModal"
    :visible="show_modal"
    :ok-only="true"
    :no-close-on-backdrop="true"
  >
    <b-row>
      <b-col cols="6">
        <b-form-group :label="$t('common.floor')">
          <v-select
            label="text"
            :options="floor_options"
            v-model="floor_id"
            :reduce="text => text.value"
            :searchable="false"
          >
            <template #no-options>
              {{ $t('common.no_options') }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group :label="$t('common.room')">
          <v-select
            label="text"
            :options="room_options"
            v-model="room_id"
            :reduce="text => text.value"
            :searchable="false"
          >
            <template #no-options>
              {{ $t('common.no_options') }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="pb-1 pl-1 pr-1 mt-1 group-edit" style="height: 200px; overflow-y: scroll">
      <b-row v-if="bed_options.length !== 0">
        <b-col>
          <b-form-checkbox
            :value="true"
            v-model="select_all"
            class="mt-1 ml-1 mr-1"
            @change="changeAllBed"
          >
            {{ $t('common.select_all') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" v-for="option in bed_options">
          <b-form-checkbox
            :value="option.bedID"
            v-model="bed_ids"
            class="mt-1 ml-1 mr-1"
            @change="changeBed(option)"
          >
            {{ option.bedNumber }}（{{ option.memberName }}）
          </b-form-checkbox>


        </b-col>
      </b-row>
    </div>

  </modal>
</template>

<script>
import vSelect from 'vue-select'
import common from '@/common'
import Modal from '@/components/Modal'

export default {
  name: 'ModalBed',
  components: {
    Modal,
    vSelect,
  },
  props: {
    title: {
      type: String,
      default() {
        return this.$t('group.addBed')
      }
    },
    facilityId: {type: Number}
  },
  data() {
    return {
      show_modal: false,
      facility_id: this.facilityId ?? common.getFacilityId(),
      floor_id: null,
      room_id: null,
      bed_ids: [],
      floor_options: [],
      room_options: [],
      bed_options: [],
      select_all: false
    }
  },
  watch: {
    facilityId: function (facilityId) {
      this.facility_id = facilityId
      this.getFloorOptions(this.facility_id)
      this.getBedOptions()
    },
    floor_id: function (floor_id) {
      this.room_id = null
      this.room_options = []
      this.bed_options = []

      if (!floor_id) {
        this.getBedOptions()
      } else {
        this.getRoomOptions(floor_id)
      }

    },
    room_id: function (room_id) {

      // if (!room_id) return
      this.getBedOptions()
    },
  },
  mounted() {
    this.getFloorOptions(this.facility_id)

    this.getBedOptions()
  },
  methods: {
    getFloorOptions: function (facility_id) {
      common.getFloorOptions(facility_id)
        .then(res => {
          this.floor_options = res
        })
    },
    getRoomOptions: function (floor_id) {
      common.getRoomOptions(floor_id)
        .then(res => {
          this.room_options = res
          this.getBedOptions()
        })
    },
    getBedOptions: function () {
      const facility_id = this.facility_id ? this.facility_id : 0
      const floor_id = this.floor_id ? this.floor_id : ''
      const room_id = this.room_id ? this.room_id : ''

      common.apiGetData('/group/GetBedMembers?facilityID=' + facility_id + '&floorID=' + floor_id + '&roomID=' + room_id)
        .then(res => {
          this.bed_options = res.data.beds
          this.checkSelectAll()
        })
    },
    runAddBed: function () {
      this.show_modal = false
    },
    hideModal: function () {
      this.show_modal = false
    },
    changeBed: function (option) {
      const type = this.bed_ids.includes(option.bedID) ? 'add' : 'delete'
      this.checkSelectAll()
      this.$emit('changeBed', type, option)
    },
    changeFacilityId: function (facilityID) {
      this.facility_id = facilityID
      this.floor_id = null
      this.room_id = null
      this.bed_ids = []
      this.floor_options = []
      this.room_options = []
      this.bed_options = []
      this.getFloorOptions(facilityID)
      this.getBedOptions()
    },
    changeAllBed: function () {
      for (const i in this.bed_options) {
        const is_include = this.bed_ids.includes(this.bed_options[i].bedID)

        if (this.select_all === true && !is_include) {
          this.bed_ids.push(this.bed_options[i].bedID)
          this.$emit('changeBed', 'add', this.bed_options[i])

        } else if (this.select_all === false && is_include) {
          this.bed_ids.splice(this.bed_ids.indexOf(this.bed_options[i].bedID), 1)
          this.$emit('changeBed', 'delete', this.bed_options[i])
        }
      }
    },
    checkSelectAll: function () {
      let flag = true
      for (const i in this.bed_options) {
        if (this.bed_ids.indexOf(this.bed_options[i].bedID) === -1) {
          flag = false
        }
      }

      this.select_all = flag
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>

</style>
